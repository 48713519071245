<template>
  <div class="bottom-navbar">
    <router-link to="/home">
      <HomeInactive class="icon inactive" />
      <HomeActive class="icon active" />
    </router-link>
    <router-link to="/stamps">
      <StampsInactive class="icon inactive" />
      <StampsActive class="icon active" />
    </router-link>
    <router-link to="/scanner">
      <ScannerInactive class="icon inactive" />
      <ScannerActive class="icon active" />
    </router-link>
    <router-link to="/leaderboard">
      <LeaderboardInactive class="icon inactive" />
      <LeaderboardActive class="icon active" />
    </router-link>
  </div>
</template>

<script>
import HomeActive from '@/assets/icons/home_active.svg';
import HomeInactive from '@/assets/icons/home_inactive.svg';
import ScannerActive from '@/assets/icons/scanner_active.svg';
import ScannerInactive from '@/assets/icons/scanner_inactive.svg';
import StampsActive from '@/assets/icons/stamps_active.svg';
import StampsInactive from '@/assets/icons/stamps_inactive_v2.svg';
import LeaderboardActive from '@/assets/icons/leaderboard_active.svg';
import LeaderboardInactive from '@/assets/icons/leaderboard_inactive.svg';

export default {
  name: 'BottomNav',
  data() {
    return {};
  },
  components: {
    HomeActive,
    HomeInactive,
    StampsInactive,
    StampsActive,
    ScannerInactive,
    ScannerActive,
    LeaderboardInactive,
    LeaderboardActive,
  },
};
</script>

<style scoped lang="scss">
.bottom-navbar {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.375em;
  padding: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 2px 5px black;

  .icon {
    width: 3.6875em;
    height: 1.6875em;
    &.active {
      display: none;
    }
    @include bp-sm-phone-landscape {
      width: 8.6875em;
    }
  }

  .router-link-active {
    .icon {
      &.inactive {
        display: none;
        opacity: 0;
      }
      &.active {
        display: inline-block;
        opacity: 1;
      }
      path {
        stroke-width: 0;
      }
    }
  }
}
</style>
