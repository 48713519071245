import { ApiService } from '@/services/api.service';
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    jwt: '',
    jwtExp: '',
    uuid: '',
    username: '',
    email: '',
    optIn: '',
    points: '',
    targetId: '',
    rank: '',
    totalCompletedTargets: 0,
    totalNumberOfTargets: 0,
    targetCompleted: false,
  },
  mutations: {
    setJWT: (state, jwt = '') => {
      state.jwt = jwt;
    },
    setJWTExp: (state, jwtExp = '') => {
      state.jwtExp = jwtExp;
    },
    setUUID: (state, uuid) => {
      state.uuid = uuid;
    },
    setUsername: (state, username) => {
      state.username = username;
    },
    setEmail: (state, email) => {
      state.email = email;
    },
    setOptIn: (state, optIn) => {
      state.optIn = optIn;
    },
    setPoints: (state, points) => {
      state.points = points;
    },
    setRank: (state, rank) => {
      state.rank = rank;
    },
    setTargetId: (state, targetId) => {
      state.targetId = targetId;
    },
    setTargetCompleted: (state, targetCompleted) => {
      state.targetCompleted = targetCompleted;
    },
    setTotalCompletedTargets: (state, totalCompletedTargets) => {
      state.totalCompletedTargets = totalCompletedTargets;
    },
    setTotalNumberOfTargets: (state, totalNumberOfTargets) => {
      state.totalNumberOfTargets = totalNumberOfTargets;
    },
  },
  actions: {
    async refreshPoints({ commit, state }) {
      if (!state.uuid || !state.jwt) return;
      const { data } = await ApiService.get(`/points/${state.uuid}`);
      const { place, points, totalCompletedTargets, totalTargets } = data;
      commit('setPoints', points);
      commit('setRank', place);
      commit('setTotalCompletedTargets', totalCompletedTargets);
      commit('setTotalNumberOfTargets', totalTargets);
    },

    parseJwt({ commit }, token) {
      ApiService.setAuth(token);
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join(''),
      );
      const userData = JSON.parse(jsonPayload);
      commit('setUUID', userData.sub);
      commit('setUsername', userData.username);
      commit('setEmail', userData.email);
      commit('setOptIn', userData.optIn);
      commit('setJWTExp', userData.exp);
      commit('setJWT', token);
    },
  },
  // modules: { user },
});
