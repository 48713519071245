<template>
  <div id="app">
    <TopNav v-if="showNav" />
    <router-view />
    <BottomNav v-if="showNav" />
  </div>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import BottomNav from '@/components/BottomNav.vue';
import { ApiService } from '@/services/api.service';

export default {
  name: 'App',
  components: { TopNav, BottomNav },
  data() {
    return {};
  },
  computed: {
    showNav() {
      return (
        this.$route.path !== '/' &&
        this.$route.path !== '/login' &&
        this.$route.path !== '/register' &&
        this.$route.path !== '/verify' &&
        this.$route.path !== '/reset-password'
      );
    },
  },
  mounted() {
    ApiService.setAuth(this.$store.state.jwt);
  },

  methods: {
    redirect() {
      ApiService.on(401, () => {
        this.$router.push('/login');
      });
    },
  },
};
</script>

<style lang="scss">
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type

@import url('https://phont.io/brandon-grotesque/fonts.css');

#app {
  font-family: $head-font-stack;
  color: $primary-white;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  height: 100%;
}
body {
  min-height: 100%;
  margin: 0;
  background-image: url(assets/images/bg_main_repeating@2x.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
}

.navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 7;
  padding: 15px;
  background-image: url(assets/images/bg_nav@2x.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  box-shadow: 0 2px 5px black;
  @include bp-md-tablet {
    background-image: url(assets/images/bg_desktop@2x.png);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }
}
.bottom-navbar {
  background-image: url(assets/images/bg_nav.png);
}

.logo {
  margin-bottom: 8em;
}
</style>
